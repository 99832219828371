// Import dependencies and configurations as needed
import React from 'react';
import config from '../utils/config.js';

// Define the StatsContent component
const StatsContent = ({ statsData }) => {
  // Destructure statsData object
  const { totalWins, totalLosses, gamesPlayed, guessDistribution, maxStreak, currentStreak } = statsData;

  // Calculate win percentage
  const calculateWinPercentage = () => {
    if (gamesPlayed === 0) {
      return 0;
    } else {
      return (totalWins / gamesPlayed) * 100;
    }
  };

  // Calculate total guesses
  const totalGuesses = Object.values(guessDistribution).reduce((acc, val) => acc + val, 0);

  // Render the StatsContent component
  return (
    <div>
      <strong>Games Played</strong><br />
      <p>{gamesPlayed}</p>
      <strong>Games Won</strong><br />
      <p>{totalWins}</p>
      <strong>Games Lost</strong><br />
      <p>{totalLosses}</p>
      <strong>Win %</strong><br />
      <p>{calculateWinPercentage().toFixed(2)}%</p>
      <strong>Current Streak</strong><br />
      <p>{currentStreak}</p>
      <strong>Max Streak</strong><br />
      <p>{maxStreak}</p>
      <strong>Guess Distribution</strong><br />
      <ul className="text-left">
        {/* Loop through the levels */}
        {Array.from({ length: config.gameGuesses }, (_, index) => {
          const level = index + 1; // Levels start from 1
          return (
            <li className="w-full h-6 relative bg-slate-50 rounded-full mb-1" key={level}>
              <span className="bg-black h-full inline-block rounded-full" style={{ minWidth: '20px', width: `${((guessDistribution[level] || 0) / totalGuesses) * 100}%` }}></span>
              <span className="absolute left-0 text-white">{guessDistribution[level] || 0}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// Export the StatsContent component
export default StatsContent;
