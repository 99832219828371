import React, { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";

const Modal = ({ showModal, closeModal, children }) => {
  const [isExiting, setIsExiting] = useState(false);

  const handleAnimationComplete = () => {
    if (isExiting) {
      closeModal();
    }
  };

  return (
    <AnimatePresence>
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">

            <motion.div
              className="relative z-50 inline-block w-full max-w-md p-6 my-8 align-middle bg-white rounded-2xl shadow-xl"
              initial={{ scale: 0}}
              animate={{ scale: 1 }}
              exit={{ scale: 0, transition: { duration: 0.2 } }}
              transition={{ type: 'spring', stiffness: 500, damping: 20 }}
              onAnimationComplete={handleAnimationComplete}
            >
              <span className="absolute top-2 right-2 flex items-center justify-center w-8 h-8 text-gray-600 cursor-pointer hover:text-gray-700" onClick={closeModal}>
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </span>
              {children}
            </motion.div>

            <motion.div className="fixed inset-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeModal}
            >
              <div className="absolute inset-0 bg-black opacity-50"></div>
            </motion.div>

          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
