import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Card from '../components/Card';

const StackOfCards = ({ quotes, onLastCardTransitionComplete }) => {
  return (
    <div className="h-[100%] flex flex-col relative">
      <div className="flex-grow text-center flex justify-center z-10">
        <AnimatePresence>
          {quotes.map((quote, i) => {
            return (
              <Card
                key={quote} // Using the quote content as a stable key
                index={i}
                quote={quote}
                total={quotes.length}
                onLastCardTransitionComplete={onLastCardTransitionComplete}
              />
            );
          })}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default StackOfCards;
