import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import config from '../utils/config.js';

const RemainingGuesses = ({ remaining }) => {
  const [bars, setBars] = useState([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true); // Set mounted to true after initial render

    const updateBars = () => {
      const newBars = [];
      for (let i = 0; i < config.gameGuesses; i++) {
        if (i < remaining) {
          newBars.push(
            <motion.div
              key={i}
              className="guess-bar bg-white h-1 rounded-full"
              style={{ width: `${100}%` }}
              initial={!mounted && { width: '0%' }} // Apply animation only if mounted
              animate={{ width: '100%' }}
              transition={{ duration: 1 }}
            />
          );
        } else {
          newBars.push(
            <motion.div
              key={i}
              className="guess-bar bg-white h-1 rounded-full"
              style={{ width: `${0}%` }}
              initial={!mounted && { width: '0%' }} // Apply animation only if mounted
              animate={{ width: 0 }}
              transition={{ duration: 1 }}
            />
          );
        }
      }
      setBars(newBars);
    };

    updateBars();
  }, [remaining, mounted]);

  return (
    <div className="px-6 pb-1">
      <div className="text-white text-base text-center pb-2">
        {remaining} {remaining === 1 ? 'guess' : 'guesses'} remaining
      </div>
      <div className="guess-bars-container flex w-full relative">
        {bars.map((bar, index) => (
          <div key={index} className="guess-bar-wrapper w-[18%] mx-[1%] backdrop-blur-lg bg-white/30 rounded-full">
            <div className="guess-bar-wrapper w-full">{bar}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RemainingGuesses;
