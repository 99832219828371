import React from 'react';
import Button from '../components/Button';

const AboutContent = ({ resetData }) => {
  return (
    <div>
      <h2 class="text-3xl font-bold mb-4">filma</h2>
      <p class="text-lg font-semibold mb-2">The quote movie guessing game</p>
      <hr className="my-4 border-t border-gray-300" />
      <p class="text-base text-gray-700">Every day, you're challenged to guess the movies behind five quotes. Test your cinema savvy, track your progress, and challenge your friends. Enhance your movie knowledge and spread your passion for film!</p>
      <p class="text-lg font-semibold mb-2 mt-4">Want to reset your stats?</p>
      <Button onClick={() => resetData()}>Reset Stats</Button>
    </div>
  );
};

export default AboutContent;
