import { useState } from 'react';

function useLocalStorage(key, initialValue) {
    // Function to retrieve stored value or use initial value if not found
    const getInitialValue = () => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(`Error retrieving ${key} from localStorage:`, error);
            return initialValue;
        }
    };

    // State to hold the value
    const [storedValue, setStoredValue] = useState(getInitialValue);

    // Function to update the value and store it in localStorage
    const setValue = (value) => {
        try {
            // Allow value to be a function to match the useState API
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Update state
            setStoredValue(valueToStore);
            // Store in localStorage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(`Error setting ${key} in localStorage:`, error);
        }
    };

    return [storedValue, setValue];
}

export default useLocalStorage;
