import React from 'react';
import { motion } from "framer-motion";

function Button({ children, color = 'blue', onClick }) {
  const getColorClasses = () => {
    switch (color) {
      case 'none':
        return '';
      case 'red':
        return 'bg-red-500 hover:bg-red-600 focus:bg-red-400';
      case 'blue':
      default:
        return 'bg-blue-500 hover:bg-blue-600 focus:bg-blue-400';
    }
  };

  return (
    <motion.button
      className={`p-2 m-1 text-white transition-colors duration-300 transform rounded-lg focus:outline-none ${getColorClasses()}`}
      onClick={onClick}
      whileTap={{ scale: 1.15 }}
      whileHover={{ scale: 0.85 }}
    >
      {children}
    </motion.button>
  );
}

export default Button;
