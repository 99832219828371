import React, { useState } from 'react';
import config from '../utils/config.js';
import Button from '../components/Button';

const ShareButton = ({ result, numGuesses, movieIndex }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = (event) => {
    let textToCopy;
    if (result) {
      textToCopy = `Filma #${movieIndex}: ${numGuesses}/${config.gameGuesses}`;
    }else {
      textToCopy = "Sorry, better luck next time";
    }
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
        event.target.blur();
      }, 2000); // Reset copied state after 2 seconds
    });
  };

  return (
    <div>
      <Button onClick={handleClick} disabled={copied}>
        {copied ? 'Copied' : 'Share'}
      </Button>
    </div>
  );
};

export default ShareButton;
