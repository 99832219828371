import React, { useState, useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './App.scss';
import config from './utils/config.js';
import MovieGuesser from './components/MovieGuesser';
import Modal from './components/Modal';
import AboutContent from './components/AboutContent';
import StatsContent from './components/StatsContent';
import Button from './components/Button';

library.add(fas, far, fab);


const App = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetStatsParam = urlParams.get('resetStats');

    const [modalContent, setModalContent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [statsData, setStatsData] = useState({
      gamesPlayed: 0,
      totalWins: 0,
      totalLosses: 0,
      totalGuesses: 0,
      guessDistribution: {},
      maxStreak: 0,
      currentStreak: 0
    });

    const openModal = content => {
        setModalContent(content);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const resetData = () => {
      localStorage.clear();
      window.location.reload(); // Refresh the app
    }

    // Let users reset their data
    if (resetStatsParam === 'true') {
        urlParams.delete('resetStats'); // Remove the resetStats parameter
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState({}, '', newUrl); // Replace the URL without the resetStats parameter
        resetData();
    }

    // Effect hook to manage game state
    useEffect(() => {

        const storedAppVersion = parseFloat(localStorage.getItem('appVersion'));

        if (isNaN(storedAppVersion) || storedAppVersion === null) {
          localStorage.setItem('appVersion', config.appVersion);
        }else {
          if (storedAppVersion !== config.appVersion) {
              resetData();
          }
        }

    });

    return (
      <div className="flex flex-col h-svh justify-center items-center bg-cover bg-center sans-font bg-[url('https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?q=80&w=2564&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]">
        <Modal showModal={showModal} closeModal={closeModal}>
            {modalContent}
        </Modal>
        <div className="flex flex-col h-svh max-w-[600px] max-h-[900px] w-full drop-shadow-2xl rounded-2xl custom-rounded-none overflow-hidden backdrop-blur-lg bg-white/30 custom-background-none">
          <header className="px-4 pb-1 pt-2">
            <div className="flex justify-between items-center"> {/* Add justify-between to align items */}
                <h1 className="logo text-3xl font-bold text-white p-2">filma</h1>
                <div className="text-lg">
                    <Button color="none" onClick={() => openModal(<AboutContent resetData={resetData} />)}><FontAwesomeIcon icon="fa-solid fa-info" className="fa-fw" /></Button>
                    <Button color="none" onClick={() => openModal(<StatsContent statsData={statsData} />)}><FontAwesomeIcon icon="fa-solid fa-chart-column" className="fa-fw" /></Button>
                </div>
            </div>
          </header>
          <MovieGuesser /* currentDate={new Date('2024-03-27')} */ setStatsData={setStatsData} />
        </div>
      </div>
    );
};

export default App;
