import React from 'react';
import Autosuggest from 'react-autosuggest';

const GuessForm = ({ handleSubmit, suggestions, onSuggestionsFetchRequested, onSuggestionsClearRequested, getSuggestionValue, renderSuggestion, guess, setGuess, result }) => {
    return (
      <div className="px-4 pt-2 pb-4 rounded-2xl">
        <div className="bg-transparent border-2 rounded-lg dark:border-gray-700 focus-within:border-primary focus-within:ring focus-within:ring-primary dark:focus-within:border-primary focus-within:ring-opacity-20">
            <form onSubmit={handleSubmit} className="flex w-full">
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        name: 'guess-input',
                        placeholder: 'Enter a guess',
                        value: guess,
                        onChange: (_, { newValue }) => setGuess(newValue),
                        disabled: result !== null,
                        className: "w-full h-12 px-4 m-1 text-white placeholder-white bg-transparent border-none appearance-none dark:text-gray-200 focus:outline-none focus:placeholder-transparent focus:ring-0"
                    }}
                />
                <button
                    type="submit"
                    disabled={result !== null}
                    className="flex items-center justify-center p-2 m-1 text-white rounded-2xl w-12 p-0 "
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                    </svg>
                </button>
            </form>
        </div>
      </div>
    );
};

export default GuessForm;
