import React, { useState, useEffect, useRef } from 'react';
import { motion, useSpring } from 'framer-motion';
import FitText from '@kennethormandy/react-fittext';

const Card = ({ quote, index, total, onLastCardTransitionComplete }) => {
  const [key, setKey] = useState(0); // Key to force remounting the FitText component
  const zIndex = total - index; // Assigning zIndex based on the index of the card
  const parentNodeRef = useRef(null); // Create a ref for the parent node

  useEffect(() => {
    // TODO
    if (index === total - 1) {
      // Call the callback function when the last card's transition is complete
      onLastCardTransitionComplete();
    }
    // Whenever text changes, increment the key to force remounting
    setKey(prevKey => prevKey + 1);
  }, [index, total, onLastCardTransitionComplete]);

  return (
    <motion.div
      className="inline-block w-[80%] h-[100%] bg-white absolute drop-shadow-2xl rounded-2xl"
      initial={{ y: -200, x: 910, scale: (20 - index) / 20, zIndex: zIndex}}
      animate={{ x: `${5 * index}%`, y: `-${5 * index}%`, scale: (20 - index) / 20, zIndex: zIndex }} // Animation target state
      transition={{ type: 'spring', ease: "anticipate", stiffness: 200, damping: 20, delay: index * 0.1 }}
      exit={{ x: -1000 }}
      key={quote} // Using the quote content as a stable key
      whileHover={index === 0 ? { rotate: -5, scale: 0.975, y: 0, transition: { type: 'spring', ease: "easeInOutQuad", stiffness: 300, damping: 30 } } : {}} // Different whileHover animation for the first card
      onAnimationComplete={() => {
        if (index === total - 1) {
          onLastCardTransitionComplete();
        }
      }}
    >
      <div className="h-full flex card p-12 overflow-hidden" ref={parentNodeRef}>
        <div className="flex w-full h-full flex-col items-center justify-center">
          <FitText key={key} vertical compressor={1.25} parent={parentNodeRef.current}>
            <React.Fragment>
              {index === 0 && (
                <motion.h2 className="text-slate-600" initial={{ y: -600, x: -600 }} animate={{ y: 0, x: 0 }} transition={{ type: 'spring', ease: "anticipate", stiffness: 200, damping: 20, delay: .09  }}>
                  "{quote}"
                </motion.h2>
              )}
            </React.Fragment>
          </FitText>
        </div>
      </div>
    </motion.div>
  );
}

export default Card;
