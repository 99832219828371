import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Autosuggest from 'react-autosuggest';
import Fuse from 'fuse.js'; // Import Fuse.js
import config from '../utils/config.js';
import useLocalStorage from '../utils/localStorage';
import moviesData from '../data/movieQuotes.json';
import GuessForm from '../components/GuessForm';
import RemainingGuesses from '../components/RemainingGuesses';
import StackOfCards from '../components/StackOfCards';
import Countdown from '../components/Countdown';
import ShareButton from '../components/ShareButton';

// Extract movie data from JSON
const movies = moviesData;

// Function to get the day of the year from a given date
const getDayOfYear = (date) => {
    const now = date || new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
};

// Function to get time until end of current day in UNIX timestamp format (in seconds)
const getTimeUntilEndOfDay = () => {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0); // Start of next day
    const timeUntilEndOfDay = endOfDay - now; // Time until end of current day in milliseconds
    return Math.floor(timeUntilEndOfDay / 1000); // Convert milliseconds to seconds
};

// MovieGuesser component
const MovieGuesser = ({ setStatsData, currentDate }) => {

    // State variables
    const [movieIndex, setMovieIndex] = useState(0);
    const [movie, setMovie] = useState('');
    const [quotes, setQuotes] = useState([]);
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
    const [guess, setGuess] = useState('');
    const [guessesRemaining, setGuessesRemaining] = useState(5);
    const [result, setResult] = useState(null);
    const [guessHistory, setGuessHistory] = useLocalStorage('movieGuessHistory', {});
    const [totalWins, setTotalWins] = useLocalStorage('totalWins', 0);
    const [totalLosses, setTotalLosses] = useLocalStorage('totalLosses', 0);
    const [gamesPlayed, setGamesPlayed] = useLocalStorage('gamesPlayed', 0);
    const [maxStreak, setMaxStreak] = useLocalStorage('maxStreak', 0);
    const [currentStreak, setCurrentStreak] = useLocalStorage('currentStreak', 0);
    const timeUntilEndOfDayInSeconds = getTimeUntilEndOfDay();

    const [guessDistribution, setGuessDistribution] = useState({});
    const [resultDisplayed, setResultDisplayed] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const [shouldAnimateOut, setAnimateOut] = useState(false); // State variable
    const [lastCardTransitionComplete, setLastCardTransitionComplete] = useState(false); // Add this line

    const [error, setError] = useState(null);

    // Calculate the current day of the year memoized
    const dayOfYear = useMemo(() => {
        return getDayOfYear(currentDate || new Date()); // Use currentDate prop if available
    }, [currentDate]); // Update when currentDate changes

    // Calculate number of guesses
    const numGuesses = guessHistory[dayOfYear]?.guesses.length;

    // Effect hook to manage game state
    useEffect(() => {

        const startDay = getDayOfYear(new Date(config.startDate));
        const offset = (dayOfYear - startDay) % Object.keys(movies).length;
        const movieKeys = Object.keys(movies);
        const movieIndex = offset >= 0 ? offset : movieKeys.length + offset;
        const movieName = movieKeys[movieIndex];
        const currentGuesses = guessHistory[dayOfYear]?.guesses || [];
        const distribution = {};

        setGuessesRemaining(config.gameGuesses - currentGuesses.length);
        setMovie(movieName);
        setQuotes(movies[movieName]);
        setCurrentQuoteIndex(currentGuesses.length);
        setGamesPlayed(Object.keys(guessHistory).length);

        // Filter guessHistory to only include results that are true
        const filteredGuessHistory = Object.values(guessHistory).filter(item => item.result == 1).map(item => ({ guesses: item.guesses.length }));

        // Calculate distribution
        Object.values(filteredGuessHistory).forEach(gameData => {
            const numGuesses = gameData.guesses;
            distribution[numGuesses] = (distribution[numGuesses] || 0) + 1;
        });

        const gameResult = guessHistory[dayOfYear];

        // Check if there's a result for the current day
        if (gameResult && gameResult.result !== undefined && gameResult.result !== null && !resultDisplayed) { // TODO  && lastCardTransitionComplete
            setResult(gameResult.result);
            setResultDisplayed(true);
        }

        // Update guessDistribution state only if there's a win
        if (result === true) {
            setGuessDistribution(distribution);
        }

        // Update stats data only when necessary
        setStatsData(prevStatsData => ({
            ...prevStatsData,
            gamesPlayed: Object.keys(guessHistory).length,
            totalWins,
            totalLosses,
            guessDistribution: distribution,
            maxStreak,
            currentStreak
        }));

    }, [dayOfYear, guessHistory, resultDisplayed, setStatsData, totalWins, totalLosses, result, movies, maxStreak, currentStreak]);

    // Handle input change
    const handleInputChange = useCallback(event => {
        setGuess(event.target.value);
    }, []);

    // Handle form submission
    const handleSubmit = useCallback(event => {
        event.preventDefault();

        setAnimateOut(true);

        const isCorrectGuess = guess.trim().toLowerCase() === movie.toLowerCase();

        // Initialize guessHistory[dayOfYear] if it doesn't exist
        const updatedGuessHistory = {
            result: isCorrectGuess ? 1 : (guessesRemaining === 1 ? 0 : null),
            guesses: guessHistory[dayOfYear]?.guesses ? [...guessHistory[dayOfYear].guesses, guess] : [guess]
        };

        // Update localStorage
        setGuessHistory({ ...guessHistory, [dayOfYear]: updatedGuessHistory });
        if (!resultDisplayed) {
            setResultDisplayed(true);
        }

        // Update game result
        if (isCorrectGuess) {
            setResult(true);
            setTotalWins(totalWins + 1);
            setCurrentStreak(currentStreak + 1);
            setMaxStreak(prevMaxStreak => Math.max(prevMaxStreak, currentStreak + 1));
        } else if (guessesRemaining === 1) {
            setResult(false);
            setTotalLosses(totalLosses + 1);
            setCurrentStreak(0);
        } else {
            setGuessesRemaining(guessesRemaining - 1);
            setCurrentQuoteIndex(currentQuoteIndex + 1);
        }
    }, [dayOfYear, guess, movie, guessesRemaining, currentQuoteIndex, guessHistory, resultDisplayed, setStatsData, totalWins, totalLosses, maxStreak, currentStreak]);


    // Calculate average guesses per game
    const averageGuesses = useMemo(() => {
        let totalGuesses = 0;
        Object.values(guessHistory).forEach(dayGuesses => {
            totalGuesses += dayGuesses.guesses.length;
        });
        return totalGuesses / (totalWins + totalLosses);
    }, [guessHistory, totalWins, totalLosses]);

    // Define the options for Fuse.js
    const fuseOptions = {
      keys: ['name'],
      includeScore: true,
      threshold: 0.3, // Adjust threshold as needed for fuzzy matching
    };

    // Function to get suggestions based on input value using Fuse.js
    const getSuggestions = value => {
      const fuse = new Fuse(Object.keys(movies), fuseOptions); // Initialize Fuse instance with movie names
      const results = fuse.search(value); // Search for matches
      return results.map(result => result.item); // Return matched movie names
    };

    // Function to get suggestion value
    const getSuggestionValue = useCallback(suggestion => suggestion, []);

    // Render suggestion item
    const renderSuggestion = useCallback(suggestion => (
        <div>
            {suggestion}
        </div>
    ), []);

    // Update suggestions when input value changes
    const onSuggestionsFetchRequested = useCallback(({ value }) => {
        setSuggestions(getSuggestions(value));
    }, [getSuggestions]);

    // Clear suggestions when needed
    const onSuggestionsClearRequested = useCallback(() => {
        setSuggestions([]);
    }, []);

    // Return JSX
    return (
        <div className="flex flex-col h-full">

          <main className="flex-1 z-0 pt-[10%]">
            {/* Display error if any */}
            {error && <div>Error: {error.message}</div>}

            {/* Render game Output */}
            {!error && result == null && (
                <StackOfCards quotes={quotes.slice(quotes.length - guessesRemaining)} onLastCardTransitionComplete={() => setLastCardTransitionComplete(true)} />
            )}

            {/* Display game result */}
            {result !== null && (
                <div className="flex flex-col text-center items-center">
                    <p>{result ? `Congratulations! Your guess is correct!` : `Sorry, you ran out of guesses. The movie was "${movie}".`}</p>
                    <Countdown timeInSeconds={timeUntilEndOfDayInSeconds} />
                    <ShareButton result={result} numGuesses={numGuesses} movieIndex={movieIndex} />
                </div>
            )}

          </main>

          {/* Render game Input */}
          {!error && result == null && (
            <footer className="p-4">

              {/* Display remaining guesses */}
              <RemainingGuesses remaining={guessesRemaining} />

              {/* Render GuessForm component */}
              <GuessForm
                  handleSubmit={handleSubmit}
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  guess={guess}
                  setGuess={setGuess}
                  result={result}
              />

            </footer>
          )}

        </div>
    );
};

// Export MovieGuesser component
export default MovieGuesser;
